import type { PaginatedReponse } from '@/components/composables/useDataTable/types'
import type { StatusResponse } from '../types'

export enum ClientStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  INACTIVE = 'inactive',
}

export type ClientSearchFiltersDTO = {
  admin?: string[]
  statuses?: string[]
}

export type CreateClientDTO = {
  client: ClientDTO
  user: ClientUserDTO
  documents: Array<ClientDocumentDTO>
}

export type ResponseClientDTO = {
  client: {
    companyName: string
    administrator: string
    email: string
    alternativeEmail: string
    address: string
    nuis: string
    city: string
    country: string
    authorizedPerson: string
    authorizedPersonPhone: string
    authorizedPersonEmail: string
    shareholders: Array<ShareholderDTO>
    users: Array<ClientUserDTO>
    documents: Array<ClientDocumentDTO>
  }
}

export type ClientDTO = {
  id?: number
  clientId?: number
  companyName: string
  administrator: string
  email: string
  alternativeEmail: string
  address: string
  nuis: string
  city: string
  country: string
  shareholders: Array<ShareholderDTO>
  authorizedPerson: string
  authorizedPersonPhone: string
  authorizedPersonEmail: string
}

export type ShareholderDTO = {
  citizenship: string
  firstName: string
  lastName: string
}

export type ClientUserDTO = {
  firstName: string
  lastName: string
  email: string
  phone: string
  roleId: number
  status?: string
}

export type ClientDocumentDTO = {
  path: string
  type: string
  expiresAt: Date
  approvedBy: number
  approvedAt: Date
  url: string
  base64: string
}

export type ClientDeactivationDTO = {
  id: number
  reason: string
  initiator: ClientUserDTO
  director: ClientUserDTO
  finance: ClientUserDTO
  administrator: ClientUserDTO
  initiated: boolean
  directorConfirmed: boolean
  financeConfirmed: boolean
  administratorConfirmed: boolean
  createdAt: Date | string
}

export type ResponseStatisticsDTO = {
  totalActive: number
  totalInactive: number
  totalPending: number
  totalRecords: number
}

export type CountriesDTO = {
  id: number
  name: string
}

export type AdminsDTO = {
  administrator: string
}

export type GetAllClientResponse = PaginatedReponse<ClientDTO>
export type FindClientResponse = StatusResponse<ResponseClientDTO>
export type CreateClientResponse = StatusResponse<{ client: ClientDTO }>
export type UpdateClientResponse = StatusResponse<undefined>
export type DeleteClientResponse = StatusResponse<undefined>
export type DeleteClientDocumentResponse = StatusResponse<undefined>
export type DeactivateClientResponse = StatusResponse<ClientDeactivationDTO>
export type StatisticsClientResponse = StatusResponse<ResponseStatisticsDTO>
export type CountriesResponse = StatusResponse<CountriesDTO[]>
export type AdminsResponse = StatusResponse<{ items: AdminsDTO[] }>
