






































































import { defineComponent, reactive } from '@vue/composition-api'
import type { Header } from '@/components/composables/useDataTable/types'
import { useDataTable } from '@/components/composables/useDataTable'
import ClientService from '@/services/client'
import ClearButton from '@/components/buttons/ClearButton.vue'
import CreateButton from '@/components/buttons/CreateButton.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import ShowButton from '@/components/buttons/ShowButton.vue'
import ClientSearchFilters from '@/views/Clients/partials/ClientSearchFilters.vue'
import ClientStatus from '@/views/Clients/partials/ClientStatus.vue'
import { UserRoles } from '@/enums/UserRoles'

export default defineComponent({
  setup() {
    const searchParams = reactive({ filters: { admin: undefined, statuses: undefined } })
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } = useDataTable(
      ClientService,
      searchParams,
    )
    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      ClientService,
      searchParams,
    }
  },
  name: 'ClientList',
  components: { ClientStatus, ClientSearchFilters, ShowButton, EditButton, CreateButton, ClearButton },
  data() {
    return {
      shareholderList: [],
      adminList: [],
      statusList: [],
      statistics: {
        totalActive: 0,
        totalInactive: 0,
        totalPending: 0,
        totalRecords: 0,
      },
    }
  },
  computed: {
    headers(): Array<Header> {
      return [
        { value: 'companyName', text: this.$t('companyName'), sortable: true },
        { value: 'country', text: this.$t('country'), sortable: true },
        { value: 'email', text: this.$t('email'), sortable: true },
        { value: 'administrator', text: this.$t('administrator'), sortable: true },
        { value: 'status', text: this.$t('status'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false },
      ]
    },
    isFinance(): boolean {
      return this.$store.getters.role === UserRoles.finance
    },
  },
  methods: {
    async fetchStatistics() {
      const { data } = await ClientService.statistics()
      this.statistics = data.data
    },
  },
  watch: {
    totalRecords: function () {
      this.fetchStatistics()
    },
  },
})
